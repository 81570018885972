<template>
    <div class="notifications-list">
        <div
            v-infinite-scroll="fetchNotifications"
            infinite-scroll-immediate-check="false"
            infinite-scroll-disabled="fetchingList"
            infinite-scroll-distance="200"
        >
            <transition-group tag="div" name="slide-right" mode="out-in">
                <template v-for="notification in feedList">
                    <notification-component
                        :key="notification.id"
                        :notification="notification"
                    />
                </template>
            </transition-group>
        </div>
        <div v-if="!feedList.length && firstAttemptToFetchData" class="not-found-memod-list">
            <div class="icon">
                <img :src="noNotificationsMessage.icon">
            </div>
            <h5>{{ noNotificationsMessage.title }}</h5>
            <p>{{ noNotificationsMessage.text }}</p>
        </div>
        <fullscreen-loader v-if="fetchingList && isLoggedIn" />
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import infiniteScroll from "vue-infinite-scroll";
import { mapGetters } from "vuex";

export default {
    name: "NotificationsList",
    components: {
        NotificationComponent: () => import(/* webpackChunkName: "notification-component" */ "./notification"),
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader" */ "@c/molecules/fullscreen-loader.vue")
    },
    directives: {
        infiniteScroll
    },
    props: {
        notificationsType: {
            type: String,
            required: true
        },
        noNotificationsMessage: {
            type: Object,
            default() {
                return {
                    icon: require("@assets/img/icons/app/inbox.svg"),
                    title: "Inbox",
                    text: "No new notifications"
                }
            }
        }
    },
    data() {
        return {
            noDataLeft: false,
            fetchingList: false,
            firstAttemptToFetchData: false,
            feedListPagination: {
                limit: 25,
                page: null,
                total_pages: 1
            },
            feedList: []
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    watch: {
        notificationsType: {
            immediate: true,
            handler() {
                this.resetNotificationsList();
                this.fetchNotifications();
            }
        }
    },
    async mounted() {
        await this.$store.dispatch("User/resetNotification");
    },
    methods: {
        resetNotificationsList() {
            this.noDataLeft = false;
            this.firstAttemptToFetchData = false;
            this.feedList = [];
            this.feedListPagination.page = null;
            this.feedListPagination.total_pages = 1;
        },
        fetchNotifications() {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            if (this.noDataLeft) {
                return;
            }

            const limit = this.feedListPagination.limit;
            const nextPage = this.feedListPagination.page ? (this.feedListPagination.page + 1) : 1;

            if (Number(nextPage) <= this.feedListPagination.total_pages) {
                this.fetchingList = true;
                const notificationType = this.notificationsType == "0" ? "" : `,notification_type_id:${this.notificationsType}`;

                axios.get(`/notifications?format=true&relationships=type&q=(is_deleted:0${ notificationType })&sort=created_at|DESC&format=true&light=true&limit=${limit}&page=${nextPage}`).then((response) => {
                    // This is temporary needed due to an inconsistency in the backend pagination,
                    // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
                    // backend after there's no memods left in the data array that is being returned by the backend
                    if (response.data.hasOwnProperty("data") && !response.data.data.length) {
                        this.noDataLeft = true;
                        return;
                    }

                    const notifications = response.data.data ? response.data.data : response.data.results;
                    const page = response.data.page ? response.data.page : response.data.meta.page.current;
                    const totalPages = response.data.total_pages ? response.data.total_pages : response.data.meta.page.total_pages;

                    for (var i = 0; i < notifications.length; i++) {
                        this.feedList.push({ ...notifications[i] });
                        this.feedListPagination.page = page;
                        this.feedListPagination.total_pages = totalPages;
                        this.fetchingList = false;
                    }

                }).finally(() => {
                    this.firstAttemptToFetchData = true;
                    this.fetchingList = false;
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.notifications-list {
    .loader {
        position: relative;
        background-color: transparent;
        height: 200px;

        /deep/ img {
            width: 100px !important;
        }
    }

    .not-found-memod-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 230px;
        margin: 0 auto;
        text-align: center;

        .icon {
            width: 72px;
            height: 72px;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 37px;
            }
        }

        h5 {
            font-size: 18px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: rgba(155, 155, 162, 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
</style>
